// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-collections-page-js": () => import("./../../../src/templates/collections-page.js" /* webpackChunkName: "component---src-templates-collections-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-impact-page-js": () => import("./../../../src/templates/impact-page.js" /* webpackChunkName: "component---src-templates-impact-page-js" */),
  "component---src-templates-librarians-page-js": () => import("./../../../src/templates/librarians-page.js" /* webpackChunkName: "component---src-templates-librarians-page-js" */),
  "component---src-templates-publishers-page-js": () => import("./../../../src/templates/publishers-page.js" /* webpackChunkName: "component---src-templates-publishers-page-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

